<script setup lang="ts">
const { durationLeftQuestDay, questPrizePool, questInfo, questData } = useFreeSpinQuest();
const loginGuard = useLoginGuard();
const { open } = useFunrizeModals();
const { t } = useT();

const emit = defineEmits<{ (event: "playQuest"): void }>();

const btnText = computed(() => {
	if (questData.value?.dayInfo?.isCompleted) {
		return t("freeSpin.promo.btn.name3");
	}
	return questData.value?.isSubscribed ? t("freeSpin.promo.btn.name2") : t("freeSpin.promo.btn.name");
});

const handleClick = () => {
	loginGuard({
		success: () => {
			emit("playQuest");
			if (questData.value?.dayInfo?.isCompleted) {
				return;
			}
			if (questInfo.value?.isAvailable) {
				open("LazyOModalFreeSpin");
				return;
			}
			open("LazyOModalFreeSpinAlternative");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/free-spin-quest/promo/card-bg.webp',
			format: 'avif',
			loading: 'lazy',
			alt: 'card-free-play'
		}"
		height="257px"
		class="card-free-play"
		@click="handleClick"
	>
		<template #default>
			<ABadge background="var(--chusovoy)" color="var(--chomutov)" variant="skew" class="top-left" autosize>
				<AText :size="12" :modifiers="['bold', 'uppercase']">{{ t("freeSpin.promo.badge.name") }}</AText>
			</ABadge>
		</template>
		<template #top>
			<div class="block">
				<AText :size="10" :modifiers="['normal', 'uppercase']" class="text-cannes title">
					{{ t("freeSpin.promo.title") }}
				</AText>
				<AText :size="12" :modifiers="['bold', 'uppercase']" class="text-cannes sub-title" as="div">
					<i18n-t keypath="freeSpin.promo.subtitle">
						<template #br>
							<br />
						</template>
					</i18n-t>
				</AText>
				<div class="prize-pool">
					<MPrizeFund variant="free-spin" :iconSize="28" img="/nuxt-img/free-spin-quest/fs.png">
						<AText :size="32" :modifiers="['bold']" class="text-chusovoy">
							{{ numberFormat(questPrizePool ?? 0) }}
						</AText>
					</MPrizeFund>
				</div>
				<AText :size="12" class="text-cannes">
					<i18n-t keypath="freeSpin.promo.timer">
						<template #timer>
							<AText :size="12" :modifiers="['bold']">{{ durationLeftQuestDay }}</AText>
						</template>
					</i18n-t>
				</AText>
			</div>
		</template>
		<template #bottom>
			<AButton variant="primary" size="md" :modifiers="[questData?.dayInfo?.isCompleted && 'disabled']">
				<AText :size="16" :modifiers="['uppercase']">{{ btnText }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-free-play {
	.sub-title {
		max-width: 220px;
	}

	.prize-pool {
		display: flex;
		margin: 8px 0;
		flex-direction: column;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.top-left {
		position: absolute;
		top: 0;
		left: 0;
	}

	.sidebar-progress {
		position: absolute;
		max-width: 240px;
		display: flex;
		gap: 8px;
		align-items: center;
		bottom: 6px;
		left: 20px;
	}

	button {
		width: 240px;
	}
}
</style>
